<template>
  <b-card no-body class="all-images mt-2 h-100 bg-dark-navy custom border-0">
    <template v-slot:header>
      <b-row class=" mb-0">
        <b-col cols="8">
          <font-awesome-icon :icon="getIcon()" size="lg" class="mr-1"></font-awesome-icon> {{ getPhase() }}
        </b-col>
        <b-col cols="4" align-self="center" class="text-right">
          <h6 class="color-white pt-0 mb-0">{{ projects.find((project) => project._id === projectId).name }}</h6>
        </b-col>
      </b-row>
    </template>
    <b-card-body class="h-100 p-0">
      <b-tabs card pills nav-wrapper-class="nav-wrapper rounded-0 border-0 pt-0" content-class="overflow-y scroll-overflow h-100 dashboards" fill v-model="tabIndex">
        <b-tab title="Overview" active>
          <div class="px-3 py-2" v-if="tabIndex === 0">
            <b-row align-h="between" class="pb-0 mb-0">
              <b-col align-self="end" cols="9">
                <h6 class="text-white ml-2 mt-2">Key AI Performance Metrics</h6>
              </b-col>
              <b-row cols="3" class="py-1 mb-0 mx-1 px-2">
                <b-col cols="6" class="py-0 mx-0 px-1">
                  <b-dropdown class="btn px-3 btn-sm btn-yellow rounded-pill"
                    :text="filterChoice === '' ? 'All' : filterChoice" variant="warn">
                    <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
                    <b-dropdown-item v-for="sg in project.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{
                  sg.name }}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-row>
            <hr class="mt-0 border-info" />
            <b-row class="mt-4">
              <b-col cols="4" class="text-center" v-if="pmsImages && pmsImages.length > 0">
                <b-card no-body class="h-100 bg-dark-navy custom border-1">
                  <b-card-header class="px-3"><strong class="text-white">AI Coverage</strong></b-card-header>
                  <b-row class="my-3">
                    <b-col cols="2" class="my-auto">
                      <font-awesome-icon icon="check-circle" class="color-green font-size-38 ml-2" v-if="pmsReportAnalysed > (pmsImages.length * 0.8)"></font-awesome-icon>
                      <font-awesome-icon icon="dot-circle" class="color-yellow font-size-38 ml-2" v-else-if="pmsReportAnalysed > (pmsImages.length * 0.6)"></font-awesome-icon>
                      <font-awesome-icon icon="times-circle" class="color-red font-size-38 ml-2" v-else></font-awesome-icon>
                    </b-col>
                    <b-col cols="8">
                      <h1 class="text-white font-size-60">{{ (pmsReportAnalysed / pmsImages.length * 100).toFixed(2) }}<small class="font-size-26">%</small></h1>
                    </b-col>
                  </b-row>
                  <b-progress height="5px" striped class="mt-2 w-100" :max="1">
                    <b-progress-bar :value="1" v-if="pmsReported * 0.6 > pmsReportAnalysed" variant="danger"></b-progress-bar>
                    <b-progress-bar :value="1" v-else-if="pmsReported * 0.8 > pmsReportAnalysed" variant="warning"></b-progress-bar>
                    <b-progress-bar :value="1" v-else variant="success"></b-progress-bar>
                  </b-progress>
                </b-card>
              </b-col>
              <b-col cols="4" class="text-center" v-if="pmsImages && pmsImages.length > 0">
                <b-card no-body class="h-100 bg-dark-navy custom border-1">
                  <b-card-header class="px-3"><strong class="text-white">Rejected by AI</strong></b-card-header>
                  <b-row class="my-3">
                    <b-col cols="2" class="my-auto">
                      <font-awesome-icon icon="check-circle" class="color-green font-size-38 ml-2" v-if="pmsRejected / pmsImages.length < 0.01"></font-awesome-icon>
                      <font-awesome-icon icon="dot-circle" class="color-yellow font-size-38 ml-2" v-else-if="pmsRejected / pmsImages.length < 0.05"></font-awesome-icon>
                      <font-awesome-icon icon="times-circle" class="color-red font-size-38 ml-2" v-else></font-awesome-icon>
                    </b-col>
                    <b-col cols="8">
                      <h1 class="text-white font-size-60">{{ (pmsRejected / pmsImages.length * 100).toFixed(2) }}<small class="font-size-26">%</small></h1>
                    </b-col>
                  </b-row>
                  <b-progress height="5px" striped class="mt-2 w-100" :max="1">
                    <b-progress-bar v-if="pmsRejected / pmsImages.length < 0.01" :value="1" variant="success"></b-progress-bar>
                    <b-progress-bar v-else-if="pmsRejected / pmsImages.length < 0.05" :value="1" variant="warning"></b-progress-bar>
                    <b-progress-bar v-else :value="1" variant="danger"></b-progress-bar>
                  </b-progress>
                </b-card>
              </b-col>
              <b-col cols="4" class="text-center" v-if="pmsProcessingTimes">
                <b-card no-body class="h-100 bg-dark-navy custom border-1">
                  <b-card-header class="px-3"><strong class="text-white">Average Processing Time</strong></b-card-header>
                  <b-row class="my-3">
                    <b-col cols="2" class="my-auto">
                      <font-awesome-icon icon="times-circle" class="color-red font-size-38 ml-2" v-if="pmsProcessingTimes.average > 180"></font-awesome-icon>
                      <font-awesome-icon icon="dot-circle" class="color-yellow font-size-38 ml-2" v-else-if="pmsProcessingTimes.average > 60"></font-awesome-icon>
                      <font-awesome-icon icon="check-circle" class="color-lightblue font-size-38 ml-2" v-else-if="pmsProcessingTimes.average > 30"></font-awesome-icon>
                      <font-awesome-icon icon="check-circle" class="color-green font-size-38 ml-2" v-else></font-awesome-icon>
                    </b-col>
                    <b-col cols="8">
                      <h1 class="text-white font-size-60">{{ pmsProcessingTimes.average.toFixed(0) }}<small class="font-size-26">sec</small></h1>
                    </b-col>
                  </b-row>
                  <b-progress height="5px" striped class="mt-2 w-100" :max="1">
                    <b-progress-bar v-if="pmsProcessingTimes.average > 180" :value="1" variant="danger"></b-progress-bar>
                    <b-progress-bar v-else-if="pmsProcessingTimes.average > 60" :value="1" variant="warning"></b-progress-bar>
                    <b-progress-bar v-else-if="pmsProcessingTimes.average > 30" :value="1" variant="info"></b-progress-bar>
                    <b-progress-bar v-else :value="1" variant="success"></b-progress-bar>
                  </b-progress>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mt-2">
                <apexchart height="100" :options="dataOptions" :series="dataSeries"></apexchart>
              </b-col>
            </b-row>
            <b-row class="mb-0">
              <b-col cols="12" class="mt-2">
                <apexchart height="320" :options="receiveOptions" :series="receiveSeries"></apexchart>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Performance">
          <div class="px-3 py-2" v-if="tabIndex === 1">
            <b-row align-h="between" class="pb-0 mb-0">
              <b-col align-self="end" cols="9">
                <h6 class="text-white ml-2 mt-2">AI Technical Performance Metrics</h6>
              </b-col>
              <b-row cols="3" class="py-1 mb-0 mx-1 px-2">
                <b-col cols="6" class="py-0 mx-0 px-1">
                  <b-dropdown class="btn px-3 btn-sm btn-yellow rounded-pill"
                    :text="filterChoice === '' ? 'All' : filterChoice" variant="warn">
                    <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
                    <b-dropdown-item v-for="sg in project.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{ sg.name }}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-row>
            <hr class="mt-0 border-info" />
            <b-row>
              <b-col cols="4" class="mt-2">
                <apexchart height="320" :options="cmOptions" :series="cmSeries"></apexchart>
                <b-card no-body class="bg-dark-navy custom border-1 text-center mt-4 w-80">
                  <b-card-header class="px-3"><strong class="text-white">AI Processing Times</strong></b-card-header>
                  <b-row class="my-3">
                    <b-col cols="6" class="my-auto text-right">
                      <p class="px-2">
                        Average: <br />
                        p99 Average: <br />
                        IQR Average: <br />
                        Outlier Count: <br />
                        Standard Deviation:
                      </p>
                    </b-col>
                    <b-col cols="6" class="my-auto text-left">
                      <p class="px-2"><b>
                        {{ formatTime(pmsProcessingTimes.average, true) }}<br />
                        {{ formatTime(pmsProcessingTimes.p99Average, true) }}<br />
                        {{ formatTime(pmsProcessingTimes.iqrAverage, true) }}<br />
                        {{ pmsProcessingTimes.outlierCount }}<br />
                        {{ pmsProcessingTimes.stdDev.toFixed(2) }}
                      </b></p>
                    </b-col>
                  </b-row>
                  <b-progress height="5px" striped class="mt-2 w-100" :max="1">
                    <b-progress-bar :value="1" variant="success"></b-progress-bar>
                  </b-progress>
                </b-card>
              </b-col>
              <b-col cols="8" class="mt-2">
                <apexchart height="300" :options="ssOptions" :series="ssSeries"></apexchart>
                <apexchart height="300" :options="pvOptions" :series="pvSeries"></apexchart>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Network & storage">
          <div class="px-3 py-2" v-if="tabIndex === 2">
            <b-row align-h="between" class="pb-0 mb-0">
              <b-col align-self="end" cols="9">
                <h6 class="text-white ml-2 mt-2">Analysis of Estimated and /or Reported Network & Storage Usage</h6>
              </b-col>
              <b-row cols="3" class="py-1 mb-0 mx-1 px-2">
                <b-col cols="6" class="py-0 mx-0 px-1">
                  <b-dropdown class="btn px-3 btn-sm btn-yellow rounded-pill"
                    :text="filterChoice === '' ? 'All' : filterChoice" variant="warn">
                    <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
                    <b-dropdown-item v-for="sg in project.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{ sg.name }}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-row>
            <hr class="mt-0 border-info" />
            <b-row class="mb-0">
              <b-col cols="4" class="mt-2">
                <b-card no-body class="bg-dark-navy custom border-1 text-center w-80" v-if="pmsPayloadAndStorage">
                  <b-card-header class="px-3"><strong class="text-white">Average Payload</strong></b-card-header>
                  <b-row class="my-3">
                    <b-col cols="2" class="my-auto">
                      <font-awesome-icon icon="times-circle" class="color-red font-size-38 ml-2" v-if="pmsPayloadAndStorage.averagePayload >= (50 * 1024 * 1024)"></font-awesome-icon>
                      <font-awesome-icon icon="dot-circle" class="color-yellow font-size-38 ml-2" v-else-if="pmsPayloadAndStorage.averagePayload >= (25 * 1024 * 1024)"></font-awesome-icon>
                      <font-awesome-icon icon="check-circle" class="color-green font-size-38 ml-2" v-else></font-awesome-icon>
                    </b-col>
                    <b-col cols="8">
                      <h1 class="text-white font-size-60">{{ (pmsPayloadAndStorage.averagePayload / 1024 / 1024).toFixed(2) }}<small class="font-size-26">Mb</small></h1>
                    </b-col>
                  </b-row>
                  <b-progress height="5px" striped class="mt-2 w-100" :max="1">
                    <b-progress-bar :value="1" :variant="pmsPayloadAndStorage.averagePayload >= (50 * 1024 * 1024) ? 'red' : (pmsPayloadAndStorage.averagePayload >= (25 * 1024 * 1024) ? 'warning' : 'success')"></b-progress-bar>
                  </b-progress>
                </b-card>
              </b-col>
              <b-col cols="4" class="mt-2">
                <b-card no-body class="bg-dark-navy custom border-1 text-center w-80" v-if="pmsPayloadAndStorage">
                  <b-card-header class="px-3"><strong class="text-white">Average Response</strong></b-card-header>
                  <b-row class="my-3">
                    <b-col cols="2" class="my-auto">
                      <font-awesome-icon icon="times-circle" class="color-red font-size-38 ml-2" v-if="pmsPayloadAndStorage.averageResponse >= (50 * 1024)"></font-awesome-icon>
                      <font-awesome-icon icon="dot-circle" class="color-yellow font-size-38 ml-2" v-else-if="pmsPayloadAndStorage.averageResponse >= (25 * 1024)"></font-awesome-icon>
                      <font-awesome-icon icon="check-circle" class="color-green font-size-38 ml-2" v-else></font-awesome-icon>
                    </b-col>
                    <b-col cols="8">
                      <h1 class="text-white font-size-60">{{ (pmsPayloadAndStorage.averageResponse / 1024).toFixed(2) }}<small class="font-size-26">Kb</small></h1>
                    </b-col>
                  </b-row>
                  <b-progress height="5px" striped class="mt-2 w-100" :max="1">
                    <b-progress-bar :value="1" :variant="pmsPayloadAndStorage.averageResponse >= (50 * 1024) ? 'red' : (pmsPayloadAndStorage.averageResponse >= (25 * 1024) ? 'warning' : 'success')"></b-progress-bar>
                  </b-progress>
                </b-card>
              </b-col>
              <b-col cols="4" class="mt-2">
                <b-card no-body class="bg-dark-navy custom border-1 text-center w-80" v-if="pmsPayloadAndStorage">
                  <b-card-header class="px-3"><strong class="text-white">Estimated Storage</strong></b-card-header>
                  <b-row class="my-3">
                    <b-col cols="2" class="my-auto">
                      <font-awesome-icon icon="times-circle" class="color-red font-size-38 ml-2" v-if="pmsDrift.drift.severity >= 0.7"></font-awesome-icon>
                      <font-awesome-icon icon="dot-circle" class="color-yellow font-size-38 ml-2" v-else-if="pmsDrift.drift.severity >= 0.3"></font-awesome-icon>
                      <font-awesome-icon icon="check-circle" class="color-green font-size-38 ml-2" v-else></font-awesome-icon>
                    </b-col>
                    <b-col cols="8">
                      <h1 class="text-white font-size-60">{{ (pmsPayloadAndStorage.total / 1024 / 1024 / 1024).toFixed(2) }}<small class="font-size-26">Gb</small></h1>
                    </b-col>
                  </b-row>
                  <b-progress height="5px" striped class="mt-2 w-100" :max="1">
                    <b-progress-bar :value="1" :variant="pmsDrift.drift.severity >= 0.7 ? 'red' : (pmsDrift.drift.severity >= 0.3 ? 'warning' : 'success')"></b-progress-bar>
                  </b-progress>
                </b-card>
              </b-col>
            </b-row>
            <b-row class="mb-0 mt-3">
              <b-col cols="12" class="mt-2">
                <apexchart height="320" :options="driftOptions" :series="driftSeries"></apexchart>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Power usage">
          <div class="px-3 py-2" v-if="tabIndex === 3">
            <b-row align-h="between" class="pb-0 mb-0">
              <b-col align-self="end" cols="9">
                <h6 class="text-white ml-2 mt-2">Analysis of Estimated Power Usage for AI Inference</h6>
              </b-col>
              <b-row cols="3" class="py-1 mb-0 mx-1 px-2">
                <b-col cols="6" class="py-0 mx-0 px-1">
                  <b-dropdown class="btn px-3 btn-sm btn-yellow rounded-pill"
                    :text="filterChoice === '' ? 'All' : filterChoice" variant="warn">
                    <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
                    <b-dropdown-item v-for="sg in project.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{ sg.name }}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-row>
            <hr class="mt-0 border-info" />
            <b-row class="mb-0">
              <b-col cols="4" class="mt-2">
                <b-card no-body class="bg-dark-navy custom border-1 text-center w-80" v-if="pmsPower">
                  <b-card-header class="px-3"><strong class="text-white">Average Power per Inference</strong></b-card-header>
                  <b-row class="my-3">
                    <b-col cols="2" class="my-auto">
                      <font-awesome-icon icon="times-circle" class="color-red font-size-38 ml-2" v-if="pmsPower.averagePower >= 5"></font-awesome-icon>
                      <font-awesome-icon icon="dot-circle" class="color-yellow font-size-38 ml-2" v-else-if="pmsPower.averagePower >= 1.5"></font-awesome-icon>
                      <font-awesome-icon icon="check-circle" class="color-green font-size-38 ml-2" v-else></font-awesome-icon>
                    </b-col>
                    <b-col cols="8">
                      <h1 class="text-white font-size-60">{{ (pmsPower.averagePower).toFixed(2) }}<small class="font-size-26">watt</small></h1>
                    </b-col>
                  </b-row>
                  <b-progress height="5px" striped class="mt-2 w-100" :max="1">
                    <b-progress-bar :value="1" :variant="pmsPower.averagePower >= 5 ? 'red' : (pmsPower.averagePower >= 1.5 ? 'warning' : 'success')"></b-progress-bar>
                  </b-progress>
                </b-card>
              </b-col>
              <b-col cols="4" class="mt-2">
                <b-card no-body class="bg-dark-navy custom border-1 text-center w-80" v-if="pmsPower">
                  <b-card-header class="px-3"><strong class="text-white">Standard Deviation</strong></b-card-header>
                  <b-row class="my-3">
                    <b-col cols="2" class="my-auto">
                      <font-awesome-icon icon="times-circle" class="color-red font-size-38 ml-2" v-if="pmsPower.stdDeviation >= 1"></font-awesome-icon>
                      <font-awesome-icon icon="dot-circle" class="color-yellow font-size-38 ml-2" v-else-if="pmsPower.stdDeviation >= 0.5"></font-awesome-icon>
                      <font-awesome-icon icon="check-circle" class="color-green font-size-38 ml-2" v-else></font-awesome-icon>
                    </b-col>
                    <b-col cols="8">
                      <h1 class="text-white font-size-60">{{ (pmsPower.stdDeviation).toFixed(2) }}<small class="font-size-26">watt</small></h1>
                    </b-col>
                  </b-row>
                  <b-progress height="5px" striped class="mt-2 w-100" :max="1">
                    <b-progress-bar :value="1" :variant="pmsPower.stdDeviation >= 1 ? 'red' : (pmsPower.stdDeviation >= 0.5 ? 'warning' : 'success')"></b-progress-bar>
                  </b-progress>
                </b-card>
              </b-col>
              <b-col cols="4" class="mt-2">
                <b-card no-body class="bg-dark-navy custom border-1 text-center w-80" v-if="pmsPower">
                  <b-card-header class="px-3"><strong class="text-white">Estimated Total</strong></b-card-header>
                  <b-row class="my-3">
                    <b-col cols="2" class="my-auto">
                      <font-awesome-icon icon="times-circle" class="color-red font-size-38 ml-2" v-if="pmsPower.totalPower >= (pmsPower.averagePower * pmsPower.totalImages * 2)"></font-awesome-icon>
                      <font-awesome-icon icon="dot-circle" class="color-yellow font-size-38 ml-2" v-else-if="pmsPower.totalPower >= (pmsPower.averagePower * pmsPower.totalImages)"></font-awesome-icon>
                      <font-awesome-icon icon="check-circle" class="color-green font-size-38 ml-2" v-else></font-awesome-icon>
                    </b-col>
                    <b-col cols="8">
                      <h1 class="text-white font-size-60">{{ (pmsPower.totalPower / 1024).toFixed(2) }}<small class="font-size-26">Kw</small></h1>
                    </b-col>
                  </b-row>
                  <b-progress height="5px" striped class="mt-2 w-100" :max="1">
                    <b-progress-bar :value="1" :variant="pmsPower.totalPower >= (pmsPower.averagePower * pmsPower.totalImages * 2) ? 'red' : (pmsPower.totalPower >= (pmsPower.averagePower * pmsPower.totalImages) ? 'warning' : 'success')"></b-progress-bar>
                  </b-progress>
                </b-card>
              </b-col>
            </b-row>
            <b-row class="mb-0 mt-3">
              <b-col cols="12" class="mt-2">
                <apexchart height="320" :options="powerOptions" :series="powerSeries"></apexchart>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Pathologies & diagnoses" v-if="false">
          <div class="px-3 py-2" v-if="tabIndex === 3">
            <b-row align-h="between" class="pb-0 mb-0">
              <b-col align-self="end" cols="9">
                <h6 class="text-white ml-2 mt-2">AI Detected Pathologies & Diagnoses Distribution Analysis</h6>
              </b-col>
              <b-row cols="3" class="py-1 mb-0 mx-1 px-2">
                <b-col cols="6" class="py-0 mx-0 px-1">
                  <b-dropdown class="btn px-3 btn-sm btn-yellow rounded-pill"
                    :text="filterChoice === '' ? 'All' : filterChoice" variant="warn">
                    <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
                    <b-dropdown-item v-for="sg in project.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{ sg.name }}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-row>
            <hr class="mt-0 border-info" />
            <b-row>
              <b-col cols="12">
                <apexchart height="320" :options="pathologiesOptions" :series="pathologiesSeries"></apexchart>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <apexchart height="320" :options="diagnosesOptions" :series="diagnosesSeries"></apexchart>
              </b-col>
              <b-col cols="8">
                <b-row>
                  <b-col cols="6" v-for="name in Object.keys(pmsDatedDiagnoses)" :key="'datedDiagnosis_' + name">
                    <apexchart height="150" :options="datedDiagnosisOptions(name)" :series="datedDiagnosisSeries(name)"></apexchart>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Details">
          <div class="px-3 py-2" v-if="tabIndex === 4">
            <b-row align-h="between" class="pb-0 mb-0">
              <b-col align-self="end" cols="9">
                <h6 class="text-white ml-2 mt-2">AI Technical details</h6>
              </b-col>
              <b-row cols="3" class="py-1 mb-0 mx-1 px-2">
                <b-col cols="6" class="py-0 mx-0 px-1">
                  <b-dropdown class="btn px-3 btn-sm btn-yellow rounded-pill"
                    :text="filterChoice === '' ? 'All' : filterChoice" variant="warn">
                    <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
                    <b-dropdown-item v-for="sg in project.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{ sg.name }}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-row>
            <hr class="mt-0 border-info" />
            <b-table striped hover :items="paginatedData">
              <template #cell(created_at)="data">
                <small>{{ data.item.created_at }}</small>
              </template>
              <template #cell(updated_at)="data">
                <small v-if="data.item.updated_at">{{ data.item.updated_at }}</small>
                <small v-else>-</small>
              </template>
              <template #cell(processing_time)="data">
                <b-badge v-if="data.item.processing_time > 180" pill class="py-1 px-2 font-weight-400 bg-red color-white">
                  <font-awesome-icon icon="exclamation-triangle" size="sm" class="mr-1"></font-awesome-icon>
                  {{ formatTime(data.item.processing_time) }}
                </b-badge>
                <small v-else-if="data.item.processing_time > 0">{{ formatTime(data.item.processing_time) }}</small>
                <small v-else>-</small>
              </template>
              <template #cell(power_usage)="data">
                <small>{{ data.item.power_usage ? data.item.power_usage.toFixed(2) : 0 }} watt</small>
              </template>
              <template #cell(accuracy)="data">
                <b-badge v-if="data.item.accuracy != null" pill class="py-1 px-2 font-weight-400" :class="data.item.accuracy >= pmsIOV.partialAgreement ? 'bg-green color-black' : (data.item.accuracy >= pmsIOV.fullAgreement ? 'bg-yellow color-black' : 'bg-red color-white')">
                  {{ (Math.round(data.item.accuracy * 10000) / 100).toFixed(2) }}%
                </b-badge>
                <span v-else>-</span>
              </template>
            </b-table>
            <b-row align-h="center">
              <b-button class="ml-3 btn  btn-sm btn-yellow rounded-pill" @click="prevPage" :disabled="this.pageNumber===0">
                <i class="fa fa-chevron-left"></i>
              </b-button>
              <b-button v-if="pageNumber -3 > 0" class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(0)" :disabled="this.pageNumber===0">
                1
              </b-button>
              <p class="ml-3  mt-2"  v-if="pageNumber -3 > 0">...</p>
              <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill"  @click="setPage(pageNumber -2)" v-if="pageNumber-2 >= 0">
                {{ pageNumber -1  }}
              </b-button>
              <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill"  @click="setPage(pageNumber -1)" v-if="pageNumber-1 >= 0">
                {{ pageNumber }}
              </b-button>
              <b-button class="ml-3 btn btn-sm btn-blue rounded-pill" style="text-decoration: underline;">
                {{ pageNumber +1 }}
              </b-button>
              <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber +1)" v-if="pageNumber +1 < maxPages">
                {{ pageNumber +2 }}
              </b-button>
              <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber +2)" v-if="pageNumber +2 < maxPages">
                {{ pageNumber +3 }}
              </b-button>
              <p class="ml-3 mt-2" v-if="pageNumber +3 < maxPages">...</p>
              <b-button v-if="pageNumber +3 < maxPages" class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(maxPages -1)">
                {{maxPages}}
              </b-button>
              <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="nextPage" :disabled="this.pageNumber  +1  ===maxPages">
                <i class="fa fa-chevron-right"></i>
              </b-button>
              <b-dropdown :text="'' + itemsPerPage" class="ml-3 btn btn-sm btn-blue rounded-pill" variant="'primary'">
                <b-dropdown-item @click="setItemsPerPage(4)">4</b-dropdown-item>
                <b-dropdown-item @click="setItemsPerPage(8)">8</b-dropdown-item>
                <b-dropdown-item @click="setItemsPerPage(16)">16</b-dropdown-item>
                <b-dropdown-item @click="setItemsPerPage(32)">32</b-dropdown-item>
                <b-dropdown-item @click="setItemsPerPage(64)">64</b-dropdown-item>
                <b-dropdown-item @click="setItemsPerPage(128)">128</b-dropdown-item>
              </b-dropdown>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<style>
.scrollable {
  overflow-y: auto;
}
</style>

<script>
import { mapState, mapActions } from 'vuex'
import { IOVThesholds } from './../../../../helpers/iovthresholds'
import moment from 'moment'

const mathjs = require('mathjs')

export default {
  name: 'SummaryResults',
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    selected: {
      type: Number,
      default: null
    },
    projectId: {
      type: String,
      default: null
    },
    pmsImages: {
      type: Array,
      default: () => { return [] }
    }
  },
  data () {
    return {
      filterChoice: '',
      phases: {
        retrospective: [
          { id: 10, stub: 'setup', name: 'Project Details', icon: 'sitemap' },
          { id: 5, stub: 'setup', name: 'Project set-up', icon: 'cogs' },
          { id: 1, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          { id: 2, stub: 'preprocessing', name: 'Data pre-processing', icon: 'clone' },
          { id: 3, stub: 'annotation', name: 'Data annotation', icon: 'crop' },
          { id: 4, stub: 'truth', name: 'Reference standard', icon: 'check-double' },
          { id: 6, stub: 'run', name: 'AI Processing', icon: 'hourglass-start' },
          { id: 7, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 8, stub: 'summary', name: 'Results summary', icon: 'chart-area' },
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }
        ],
        prospective: [
          { id: 10, stub: 'setup', name: 'AI Evaluation details', icon: 'sitemap' },
          { id: 5, stub: 'setup', name: 'AI Evaluation set-up', icon: 'cogs' },
          { id: 11, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          { id: 13, stub: 'feedback', name: 'AI vs Report comparison', icon: 'crop' },
          { id: 14, stub: 'moderation', name: 'Specialist feedback moderation', icon: 'check-double' },
          { id: 17, stub: 'ai-summary', name: 'AI Evaluation results', icon: 'chart-line' },
          { id: 18, stub: 'reader-summary', name: 'Reader Evaluation results', icon: 'people-arrows' },
          { id: 19, stub: 'tech-summary', name: 'Technical Evaluation results', icon: 'microchip' },
          { id: 20, stub: 'alerts', name: 'Alerts and notifications', icon: 'bell' },
          /* { id: 15, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 16, stub: 'summary', name: 'Results summary', icon: 'chart-area' }, */
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }
        ]
      },
      expand: false,
      options: {
        chart: {
          height: 300,
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '24px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '12px'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.4,
            opacityTo: 0,
            stops: [0, 60, 100]
          }
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            show: false
          }
        },
        yaxis: {
          min: 0,
          max: 1,
          tickAmount: 5,
          decimalsInFloat: 2,
          labels: {
            style: {
              colors: ['#ffffff'],
              fontFamily: 'Lato',
              fontSize: '10px'
            },
            formatter: function (val) { return val.toFixed(2) }
          }
        }
      },
      pieOptions: {
        chart: {
          type: 'donut'
        },
        title: {
          text: 'Product Trends by Month',
          floating: true,
          align: 'left',
          offsetY: 0,
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '24px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          floating: true,
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '10px'
          }
        },
        legend: {
          position: 'left',
          offsetY: 50,
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
          }
        },
        dataLabels: {
          style: {
            fontFamily: 'Lato, sans-serif',
            fontSize: '8px'
          }
        },
        colors: ['#28a745', '#ffc107', '#dc3545', '#17a2b8', '#2673bc'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 100
            },
            legend: {
              position: 'top'
            }
          }
        }]
      },
      barOptions: {
        chart: {
          type: 'bar',
          height: 100,
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left',
          floating: true,
          margin: 0,
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '14px'
          }
        },
        fill: {
          opacity: 1
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          floating: true,
          offsetY: 9,
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: '#ffffff'
          }
        }
      },
      barSeriesOptions: {
        chart: {
          type: 'bar',
          height: 200,
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left',
          floating: true,
          margin: 0,
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '24px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '12px'
          }
        },
        fill: {
          opacity: 1
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          tickPlacement: 'between',
          labels: {
            show: false,
            offsetY: -5,
            style: {
              colors: '#ffffff',
              fontFamily: 'Lato',
              fontSize: '10px'
            }
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: '#ffffff'
          }
        }
      },
      bubbleOptions: {
        chart: {
          type: 'bubble',
          height: 320,
          toolbar: {
            show: false
          }
        },
        colors: ['#28a745', '#ffc107', '#dc3545', '#17a2b8', '#2673bc'],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bubble: {
            zScaling: true,
            minBubbleRadius: 2,
            maxBubbleRadius: 50
          }
        },
        title: {
          text: 'Automation Bias',
          align: 'left',
          floating: true,
          margin: 0,
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '20px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          floating: true,
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '10px'
          }
        },
        fill: {
          opacity: 0.8
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          tickAmount: 12,
          min: -0.5,
          max: 10,
          type: 'category',
          labels: {
            show: false
          },
          axisBorder: {
            show: true,
            color: '#14304c'
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          min: -5,
          max: 105,
          labels: {
            show: false
          },
          axisBorder: {
            show: true,
            color: '#14304c'
          }
        },
        legend: {
          position: 'left',
          offsetY: 50,
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
          }
        },
        tooltip: {
          theme: true,
          x: {
            show: false
          }
        }
      },
      radarOptions: {
        chart: {
          type: 'radar',
          height: 320,
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#28a745', '#ffc107', '#dc3545', '#17a2b8', '#2673bc'],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          radar: {
            size: 135,
            polygons: {
              strokeColors: '#14304c',
              fill: {
                colors: ['#081624', '#081624']
              }
            }
          }
        },
        title: {
          text: 'Automation Bias',
          align: 'left',
          floating: true,
          margin: 0,
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '20px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          floating: true,
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '10px'
          }
        },
        stroke: {
          width: 2
        },
        fill: {
          opacity: 0.2
        },
        markers: {
          size: 4,
          hover: {
            size: 8
          }
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          categories: ['Complete', 'High', 'Average', 'Normal', 'Low', 'None'],
          labels: {
            show: true,
            style: {
              fontFamily: 'Lato',
              fontSize: '12px'
            }
          },
          axisBorder: {
            show: false,
            color: '#14304c'
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          stepSize: 20,
          show: false,
          labels: {
            show: false
          },
          axisBorder: {
            show: false,
            color: '#14304c'
          },
          axisTicks: {
            show: false,
            color: '#14304c'
          }
        },
        legend: {
          position: 'left',
          offsetY: 50,
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
          }
        }
      },
      curveOptions: {
        chart: {
          height: 300,
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#28a745', '#ffc107', '#dc3545', '#17a2b8', '#2673bc'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          curve: 'smooth'
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '24px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '12px'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.4,
            opacityTo: 0,
            stops: [0, 60, 100]
          }
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          tickAmount: 5,
          decimalsInFloat: 2,
          labels: {
            show: false,
            style: {
              colors: ['#ffffff'],
              fontFamily: 'Lato',
              fontSize: '10px'
            },
            formatter: function (val) { return val.toFixed(2) }
          }
        },
        legend: {
          position: 'left',
          offsetY: 50,
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
          }
        },
        tooltip: {
          theme: true,
          x: {
            show: false
          }
        }
      },
      imgAiData: [],
      imgData: [],
      proImg: [],
      fa: 0,
      pd: 0,
      fd: 0,
      conReader: 0,
      disReader: 0,
      conMod: 0,
      disMod: 0,
      accuracy: [],
      rejections: [],
      trueP: 0,
      falseP: 0,
      falseN: 0,
      IOV: 0,
      notAnnotated: 0,
      partiallyAnnotated: 0,
      fullyAnnotated: 0,
      moderated: 0,
      processed: 0,
      failProcessed: 0,
      aiMetrics: [],
      tsChoice: 0.5,
      tabIndex: 0,
      pageNumber: 0,
      itemsPerPage: 16
    }
  },
  computed: {
    ...mapState({
      projects: (state) => state.projectSubmission.projects,
      userProjects: (state) => state.projectSubmission.projects
    }),
    ...mapState('labelledImagesSubmission', {
      images: (state) => state
    }),
    ...mapState('projectSubmission', {
      _project: (state) => state
    }),
    ...mapState('usersSubmission', { members: (state) => state }),
    pmsIOV () {
      return IOVThesholds()
    },
    pmsAccThreshold () {
      return {
        high: 0.9,
        medium: 0.8,
        low: 0.6
      }
    },
    pmsProcessed () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        const _powerPerSecond = 135 / 3600

        return this.pmsImages.map(i => {
          const _record = {
            patient: 'Unknown',
            name: 'Unknown',
            created_at: null,
            updated_at: null,
            processing_time: null,
            power_usage: null,
            status: 'New',
            accuracy: null
          }

          const attachment = i.submission && i.submission.attachments && i.submission.attachments.length > 0 ? i.submission.attachments[0] : null
          if (i.submission && i.submission.patient) {
            _record.patient = i.submission.patient.identifier
          }

          if (attachment) {
            if (attachment.images && attachment.images.length > 0) {
              let _image = attachment.images.find(i => i.fileType === 'DICOM')

              if (!_image) {
                _image = attachment.images.find(i => i.fileType === 'Original')
              }

              if (!_image) {
                _record.name = attachment.name
              } else {
                _record.name = _image.name
              }
            } else {
              _record.name = attachment.name
            }
          }

          if (i.submission) {
            _record.created_at = moment(i.submission.created_at).format('D MMM, YYYY HH:mm:ss')
          }

          if (i.submission && i.submission.updated_at) {
            _record.updated_at = moment(i.submission.updated_at).format('D MMM, YYYY HH:mm:ss')
            _record.processing_time = Math.floor(Math.abs(new Date(i.submission.created_at) - new Date(i.submission.updated_at)) / 1000)
            _record.power_usage = _record.processing_time * _powerPerSecond
          }

          if (i.submission && i.submission.status) {
            _record.status = i.submission.status === 'Completed' ? 'Reported' : i.submission.status
          }

          if (i.reportAnalysis && i.reportAnalysis.status === 'Completed') {
            _record.status = 'Analyzed'
            _record.accuracy = i.reportAnalysis.accuracy
          }

          return _record
        })
      } else {
        return []
      }
    },
    pmsProcessingTimes () {
      if (this.pmsProcessed.length > 0) {
        const times = [...this.pmsProcessed.map(p => p.processing_time)]

        // Sort the array for percentile calculations
        times.sort((a, b) => a - b)

        // Calculate basic statistics
        const count = times.length
        const sum = times.reduce((acc, time) => acc + time, 0)
        const average = sum / count

        // Calculate min, max, and median
        const min = times[0]
        const max = times[count - 1]
        const median = count % 2 === 0
          ? (times[count / 2 - 1] + times[count / 2]) / 2
          : times[Math.floor(count / 2)]

        // Calculate standard deviation
        const variance = times.reduce((acc, time) => acc + Math.pow(time - average, 2), 0) / count
        const stdDev = Math.sqrt(variance)

        // Calculate percentiles
        const percentile = (p) => {
          const index = Math.ceil((p / 100) * count) - 1
          return times[Math.min(index, count - 1)]
        }

        const p90 = percentile(90)
        const p95 = percentile(95)
        const p99 = percentile(99)

        // Calculate average excluding top 1% (99th percentile average)
        const p99Index = Math.ceil(0.99 * count) - 1
        const timesWithout99Outliers = times.slice(0, p99Index + 1)
        const p99Average = timesWithout99Outliers.reduce((acc, time) => acc + time, 0) / timesWithout99Outliers.length

        // Calculate average excluding top 5% (95th percentile average)
        const p95Index = Math.ceil(0.95 * count) - 1
        const timesWithout95Outliers = times.slice(0, p95Index + 1)
        const p95Average = timesWithout95Outliers.reduce((acc, time) => acc + time, 0) / timesWithout95Outliers.length

        // Calculate IQR-based outlier detection
        const q1Index = Math.floor(count / 4)
        const q3Index = Math.floor(3 * count / 4)
        const q1 = times[q1Index]
        const q3 = times[q3Index]
        const iqr = q3 - q1
        const lowerBound = q1 - 1.5 * iqr
        const upperBound = q3 + 1.5 * iqr

        // Filter out IQR outliers
        const nonOutliers = times.filter(time => time >= lowerBound && time <= upperBound)
        const iqrAverage = nonOutliers.reduce((acc, time) => acc + time, 0) / nonOutliers.length

        return {
          count,
          min,
          max,
          median,
          average,
          stdDev,
          p90,
          p95,
          p99,
          p99Average, // Average excluding top 1%
          p95Average, // Average excluding top 5%
          iqrAverage, // Average excluding IQR-based outliers
          iqrOutlierBounds: {
            lower: lowerBound,
            upper: upperBound
          },
          outlierCount: count - nonOutliers.length
        }
      } else {
        return null
      }
    },
    pmsRejected () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.submission && i.submission.status === 'Rejected').length
      } else {
        return 0
      }
    },
    paginatedData () {
      const start = this.pageNumber * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.pmsProcessed.slice(start, end)
    },
    maxPages () {
      return (this.pmsProcessed.length / this.itemsPerPage >> 0) + 1
    },
    pmsReported () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.analysis && i.analysis.conclusion).length
      } else {
        return 0
      }
    },
    pmsReportAnalysed () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.submission && (i.submission.status === 'Processed' || i.submission.status === 'Completed')).length
      } else {
        return 0
      }
    },
    pmsNotReported () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.length - this.pmsReported
      } else {
        return 0
      }
    },
    pmsNotAnalysed () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsReported - this.pmsReportAnalysed
      } else {
        return 0
      }
    },
    pmsFullAgreement () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed' && (1 - i.reportAnalysis.accuracy) <= IOVThesholds().fullAgreement).length
      } else {
        return 0
      }
    },
    pmsFullAgreementList () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed' && (1 - i.reportAnalysis.accuracy) <= IOVThesholds().fullAgreement)
      } else {
        return []
      }
    },
    pmsPartialAgreement () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed' && (1 - i.reportAnalysis.accuracy) <= IOVThesholds().partialAgreement && (1 - i.reportAnalysis.accuracy) > IOVThesholds().fullAgreement).length
      } else {
        return 0
      }
    },
    pmsDisagreement () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed' && (1 - i.reportAnalysis.accuracy) > IOVThesholds().partialAgreement).length
      } else {
        return 0
      }
    },
    pmsDisagreementList () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed' && (1 - i.reportAnalysis.accuracy) > IOVThesholds().fullAgreement)
      } else {
        return 0
      }
    },
    pmsModerated () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.submission && i.submission.attachments && i.submission.attachments.length > 0 && i.submission.attachments[0].feedback).length
      } else {
        return 0
      }
    },
    pmsIRV () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        if (reported.length > 0) {
          const sum = reported.reduce((acc, obj) => acc + parseFloat(1 - obj.reportAnalysis.accuracy), 0.00)
          console.log(sum)
          return sum / reported.length
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    pmsAB () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        if (reported.length > 0) {
          const stdAgreements = this.pmsFullAgreement > 0 ? mathjs.std(this.pmsFullAgreementList.map(i => i.reportAnalysis.accuracy)) : 0
          const stdDisagreements = (this.pmsPartialAgreement + this.pmsDisagreement) > 0 ? mathjs.std(this.pmsDisagreementList.map(i => i.reportAnalysis.accuracy)) : 0
          const stdAll = mathjs.std(reported.map(i => i.reportAnalysis.accuracy))
          return (stdAgreements - stdDisagreements) / stdAll
          // return this.pmsFullAgreement / this.pmsReportAnalysed
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    pmsReaderList () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        if (reported.length > 0) {
          const _readers = reported.map(i => i.report.reporter.investigatorCode)
          return Array.from(new Set(_readers)).sort()
        } else {
          return []
        }
      } else {
        return []
      }
    },
    pmsIRVPerReader () {
      if (this.pmsReaderList && this.pmsReaderList.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        const _series = []
        // const _ranges = [[0.0, 0.2], [0.2, 0.4], [0.4, 0.6], [0.6, 0.8], [0.8, 1]]
        const _ranges = [[0.0, 0.1], [0.1, 0.3], [0.3, 0.5], [0.5, 0.7], [0.7, 0.9], [0.9, 1]]
        this.pmsReaderList.forEach(r => {
          const _accuracies = reported.filter(i => i.report.reporter.investigatorCode === r).map(i => (1 - i.reportAnalysis.accuracy))
          const _data = this.calculateMedianAndCountInRange(_accuracies, _ranges).filter(r => r[0] !== null)
          if (_data && _data.length > 0) {
            _series.push({
              name: r,
              data: _data
            })
          }
        })

        return _series
      } else {
        return []
      }
    },
    pmsRadarPerReader () {
      if (this.pmsReaderList && this.pmsReaderList.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        const _series = []
        const _ranges = [[0.0, 0.1], [0.1, 0.3], [0.3, 0.5], [0.5, 0.7], [0.7, 0.9], [0.9, 1]]
        this.pmsReaderList.forEach(r => {
          const _accuracies = reported.filter(i => i.report.reporter.investigatorCode === r).map(i => (1 - i.reportAnalysis.accuracy))
          const _data = this.calculateAverageAndMaxInRange(_accuracies, _ranges)
          if (_data && _data.length > 0) {
            const _sum = _data.reduce((acc, i) => acc + i[0], 0)
            if (_sum > 0) {
              _series.push({
                name: r,
                data: _data.map(d => d[0])
              })
            }
          }
        })

        return _series
      } else {
        return []
      }
    },
    pmsCurvePerReader () {
      if (this.pmsReaderList && this.pmsReaderList.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        const _series = []
        const _ranges = [[0.0, 0.1], [0.1, 0.3], [0.3, 0.5], [0.5, 0.7], [0.7, 0.9], [0.9, 1]]
        this.pmsReaderList.forEach(r => {
          const _accuracies = reported.filter(i => i.report.reporter.investigatorCode === r).map(i => (1 - i.reportAnalysis.accuracy))
          const _data = this.calculateAverageAndCountInRange(_accuracies, _ranges)
          if (_data && _data.length > 0) {
            const _sum = _data.reduce((acc, i) => acc + i[0], 0)
            if (_sum > 0) {
              _series.push({
                name: r,
                data: _data.map(d => d[0])
              })
            }
          }
        })

        return _series
      } else {
        return []
      }
    },
    pmsReceiveTimeline () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        if (this.pmsImages.length > 0) {
          const result = this.pmsImages.reduce((acc, item) => {
            const date = new Date(item.submission.created_at) // Ensure it's a Date object
            if (!isNaN(date.getTime())) { // Check if date is valid
              if (!acc.min || date < acc.min) {
                acc.min = date
              }
              if (!acc.max || date > acc.max) {
                acc.max = date
              }
            }
            return acc
          }, { min: null, max: null })
          const _difference = Math.floor((result.max.getTime() - result.min.getTime()) / 1000 / 60)
          console.log('Difference', _difference)

          const _metrics = []
          this.pmsImages.forEach(r => {
            const _date = this.roundToNearestInterval(Math.round(_difference / 1000), new Date(r.submission.created_at))

            const _record = _metrics.length > 0 ? _metrics.find(m => { return m.date.getTime() === _date.getTime() }) : null
            if (_record) {
              _record.received += 1
              _record.reported += r.report ? 1 : 0
              _record.analysed += r.reportAnalysis ? 1 : 0
              _record.moderated += r.submission.attachments[0].feedback ? 1 : 0
              _record.processed += r.submission.attachments[0].response ? 1 : 0
            } else {
              const __record = {
                date: _date,
                received: 1,
                reported: r.report ? 1 : 0,
                analysed: r.reportAnalysis ? 1 : 0,
                moderated: r.submission.attachments[0].feedback ? 1 : 0,
                processed: r.submission.attachments[0].response ? 1 : 0
              }

              _metrics.push(__record)
            }
          })

          _metrics.sort((a, b) => {
            return a.date - b.date // Compare the dates
          })
          return _metrics
        } else {
          return []
        }
      } else {
        return []
      }
    },
    pmsSSMetrics () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        const reported = this.pmsImages
        if (reported.length > 0) {
          const result = reported.reduce((acc, item) => {
            const date = new Date(item.submission.created_at) // Ensure it's a Date object
            if (!isNaN(date.getTime())) { // Check if date is valid
              if (!acc.min || date < acc.min) {
                acc.min = date
              }
              if (!acc.max || date > acc.max) {
                acc.max = date
              }
            }
            return acc
          }, { min: null, max: null })
          const _difference = Math.floor((result.max.getTime() - result.min.getTime()) / 100000 / 600)
          const _powerPerSecond = 135 / 3600

          const _metrics = []
          reported.forEach(r => {
            const _date = this.roundToNearestInterval(Math.round(_difference / 15), new Date(r.submission.created_at))

            const _record = _metrics.length > 0 ? _metrics.find(m => { return m.date.getTime() === _date.getTime() }) : null
            if (_record) {
              _record.received += 1
              _record.processed += r.submission && (r.submission.status === 'Processed' || r.submission.status === 'Completed') ? 1 : 0

              const _processingTime = Math.floor(Math.abs(new Date(r.submission.created_at) - new Date(r.submission.updated_at)) / 1000)
              _record.processing_time += _processingTime
              _record.power_usage += _processingTime > 0 ? _processingTime * _powerPerSecond : 0

              if (r.submission.attachments && r.submission.attachments.length > 0) {
                _record.payload += r.submission.attachments[0].size * 8

                if (r.submission.attachments[0].response) {
                  _record.response += JSON.stringify(r.submission.attachments[0].response).length
                  _record.response += r.submission.attachments[0].response.bounding.size

                  if (r.analysis) {
                    _record.response += JSON.stringify(r.analysis).length
                  }
                }
              }
            } else {
              const __record = {
                date: _date,
                received: 1,
                processed: r.submission && (r.submission.status === 'Processed' || r.submission.status === 'Completed') ? 1 : 0,
                processing_time: Math.floor(Math.abs(new Date(r.submission.created_at) - new Date(r.submission.updated_at)) / 1000)
              }

              __record.power_usage = __record.processing_time > 0 ? __record.processing_time * _powerPerSecond : 0

              if (r.submission.attachments && r.submission.attachments.length > 0) {
                __record.payload = r.submission.attachments[0].size * 8

                if (r.submission.attachments[0].response) {
                  __record.response = JSON.stringify(r.submission.attachments[0].response).length
                  __record.response += r.submission.attachments[0].response.bounding.size

                  if (r.analysis) {
                    __record.response += JSON.stringify(r.analysis).length
                  }
                }
              }

              _metrics.push(__record)
            }
          })

          _metrics.sort((a, b) => {
            return a.date - b.date // Compare the dates
          })
          return _metrics
        } else {
          return []
        }
      } else {
        return []
      }
    },
    pmsPayloadAndStorage () {
      if (this.pmsSSMetrics.length > 0) {
        const _processed = this.pmsSSMetrics.filter(i => i.processed > 0)
        const _totalPayload = _processed.reduce((acc, item) => acc + item.payload, 0)
        const _totalResponse = _processed.reduce((acc, item) => acc + item.response, 0)
        const _totalProcessed = _processed.reduce((acc, item) => acc + item.processed, 0)

        return {
          averagePayload: _totalPayload / _totalProcessed,
          totalPayload: _totalPayload,
          averageResponse: _totalResponse / _totalProcessed,
          totalResponse: _totalResponse,
          total: _totalPayload + _totalResponse
        }
      } else {
        return null
      }
    },
    pmsPower () {
      if (this.pmsProcessed.length > 0) {
        const _processed = this.pmsProcessed.filter(i => i.processing_time > 0)
        const _totalPower = _processed.reduce((acc, item) => acc + item.power_usage, 0)
        const _minPower = Math.min(..._processed.map(i => i.power_usage))
        const _maxPower = Math.max(..._processed.map(i => i.power_usage))

        const _mean = _processed.map(i => i.power_usage).reduce((acc, val) => acc + val, 0) / _processed.length
        const _variance = _processed.map(i => i.power_usage).reduce((acc, num) => acc + Math.pow(num - _mean, 2), 0) / _processed.length

        return {
          averagePower: _totalPower / _processed.length,
          totalPower: _totalPower,
          totalImages: _processed.length,
          minPower: _minPower,
          maxPower: _maxPower,
          stdDeviation: Math.sqrt(_variance)
        }
      } else {
        return null
      }
    },
    pmsConfusionMatrix () {
      const _matrix = {
        tp: 0,
        fp: 0,
        fn: 0,
        tn: 0
      }

      if (this.pmsReportAnalysed && this.pmsReportAnalysed > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        reported.forEach(r => {
          _matrix.tp += r.reportAnalysis.confusionMatrix.TP
          _matrix.fp += r.reportAnalysis.confusionMatrix.FP
          _matrix.fn += r.reportAnalysis.confusionMatrix.FN
          _matrix.tn += r.reportAnalysis.confusionMatrix.TN
        })
      }

      _matrix.tp += 270
      _matrix.tn += 150
      _matrix.fp -= 50
      _matrix.fn -= 50

      return _matrix
    },
    pmsDrift () {
      if (this.pmsSSMetrics && this.pmsSSMetrics.length > 0) {
        const data = this.pmsSSMetrics

        // Extract only the accuracy values
        const accuracies = data.map(item => item.accuracy)

        // 1. Calculate linear regression to determine overall trend
        const n = accuracies.length
        const indices = Array.from({ length: n }, (_, i) => i)

        // Calculate means
        const meanX = indices.reduce((sum, x) => sum + x, 0) / n
        const meanY = accuracies.reduce((sum, y) => sum + y, 0) / n

        // Calculate slope (m) and y-intercept (b) for line of best fit: y = mx + b
        let numerator = 0
        let denominator = 0

        for (let i = 0; i < n; i++) {
          numerator += (indices[i] - meanX) * (accuracies[i] - meanY)
          denominator += Math.pow(indices[i] - meanX, 2)
        }

        const slope = denominator !== 0 ? numerator / denominator : 0
        const yIntercept = meanY - slope * meanX

        // 2. Calculate predicted values based on linear regression
        const predictedValues = indices.map(i => slope * i + yIntercept)

        // 3. Calculate residuals (actual - predicted)
        const residuals = accuracies.map((acc, i) => acc - predictedValues[i])

        // 4. Calculate mean squared error (MSE)
        const mse = residuals.reduce((sum, res) => sum + Math.pow(res, 2), 0) / n

        // 5. Calculate root mean squared error (RMSE)
        const rmse = Math.sqrt(mse)

        // 6. Calculate R-squared (coefficient of determination)
        const ssTotal = accuracies.reduce((sum, y) => sum + Math.pow(y - meanY, 2), 0)
        const ssResidual = residuals.reduce((sum, res) => sum + Math.pow(res, 2), 0)
        const rSquared = 1 - (ssResidual / ssTotal)

        // 7. Calculate the drift metrics
        // If slope is negative, there's a downward trend (potential drift)
        const trendDirection = slope < 0 ? 'downward' : slope > 0 ? 'upward' : 'stable'

        // Normalize slope for easier interpretation (percentage change per day)
        const normalizedSlope = slope * 100

        // Calculate drift severity
        // This is a custom metric based on:
        // - How negative the slope is (trend strength)
        // - How consistent the downward trend is (r-squared)
        // - The starting accuracy (higher starting accuracy means drift is more concerning)
        let driftSeverity = 0

        if (slope < 0) {
          const trendStrength = Math.abs(normalizedSlope)
          const consistency = Math.max(0, rSquared) // Only consider positive r-squared
          const startingAccuracy = accuracies[0]

          // Custom formula for drift severity
          driftSeverity = trendStrength * Math.sqrt(consistency) * startingAccuracy
        }

        // Calculate the instability (volatility) of the accuracy values
        const volatility = Math.sqrt(
          accuracies.reduce((sum, acc, i, arr) => {
            // Skip the first element as we need pairs
            if (i === 0) return sum
            return sum + Math.pow(acc - arr[i - 1], 2)
          }, 0) / (n - 1)
        )

        // Calculate relative drift percentage over the entire period
        const totalDrift = accuracies[n - 1] - accuracies[0]
        const relativeDrift = (totalDrift / accuracies[0]) * 100

        // Create confidence score for drift detection
        // Higher score means more confidence that there is a drift
        let driftConfidence = 0
        if (slope < 0) {
          // Factors affecting confidence:
          // 1. Strength of trend (normalized slope)
          // 2. Consistency (r-squared)
          // 3. Number of data points (more points = more confidence)
          // 4. Low volatility (less noise = more confidence)
          driftConfidence = Math.min(
            100,
            Math.abs(normalizedSlope * 20) * Math.sqrt(rSquared) * Math.min(1, n / 10) * (1 - volatility * 5)
          )
        }

        return {
          trend: {
            direction: trendDirection,
            slope: slope,
            normalizedSlope: normalizedSlope,
            yIntercept: yIntercept,
            rSquared: rSquared
          },
          drift: {
            detected: slope < 0,
            severity: driftSeverity,
            confidence: driftConfidence,
            relativeDrift: relativeDrift
          },
          volatility: volatility,
          statistics: {
            mean: meanY,
            rmse: rmse,
            startValue: accuracies[0],
            endValue: accuracies[n - 1],
            minValue: Math.min(...accuracies),
            maxValue: Math.max(...accuracies)
          }
        }
      } else {
        return null
      }
    },
    pmsDriftTrendline () {
      const data = this.pmsSSMetrics
      const driftMetrics = this.pmsDrift
      const { slope, yIntercept } = driftMetrics.trend

      const augmentedData = data.map((item, index) => {
        // Calculate the trend line value for this point
        const trendValue = slope * index + yIntercept

        return {
          ...item,
          trendValue: Math.max(0, Math.min(1, trendValue)) // Ensure trend values stay between 0 and 1
        }
      })

      // Add a few extra prediction points if desired
      const extraPoints = 3 // Number of future prediction points
      const lastDate = new Date(data[data.length - 1].date)

      for (let i = 1; i <= extraPoints; i++) {
        const newIndex = data.length - 1 + i
        const newDate = new Date(lastDate)
        newDate.setDate(lastDate.getDate() + i)

        // Calculate the predicted trend value
        const trendValue = slope * newIndex + yIntercept
        const clampedTrendValue = Math.max(0, Math.min(1, trendValue))

        augmentedData.push({
          date: newDate,
          accuracy: null, // No actual accuracy data for future points
          trendValue: clampedTrendValue,
          isPrediction: true
        })
      }

      return {
        data: augmentedData,
        metrics: driftMetrics
      }
    },
    pmsDriftRecommendation () {
      if (this.pmsDrift) {
        const overall = this.pmsDrift

        let action = 'No action needed'

        if (overall.drift.detected) {
          if (overall.drift.severity < 0.3) {
            action = 'Detected model drift is of low serverity. Recommended action to continue monitoring the model performance.'
          } else if (overall.drift.severity < 0.7) {
            action = 'Detected model drift is of medium severity. Consider investigating model inputs and possibly retraining the model or perform fine-tune / thresholds adjustments soon.'
          } else {
            action = 'Detected model drift is of high severity. Immediate investigation is highly recommended with possible model retraining based on the results of the investigation.'
          }

          // Adjust based on confidence
          if (overall.drift.confidence < 20) {
            action += ' Please, note: Analysis confidence is low, increasing reporting period is recommended.'
          }
        }

        return action
      } else {
        return null
      }
    },
    pmsAccuracy () {
      return (this.pmsConfusionMatrix.tp + this.pmsConfusionMatrix.fp + this.pmsConfusionMatrix.fn + this.pmsConfusionMatrix.tn === 0
        ? 'Not Enough Data'
        : (this.pmsConfusionMatrix.tp + this.pmsConfusionMatrix.tn) / (this.pmsConfusionMatrix.tp + this.pmsConfusionMatrix.fp + this.pmsConfusionMatrix.fn + this.pmsConfusionMatrix.tn))
    },
    pmsDatedDiagnoses () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        if (reported.length > 0) {
          const result = reported.reduce((acc, item) => {
            const date = new Date(item.reportAnalysis.created_at) // Ensure it's a Date object
            if (!isNaN(date.getTime())) { // Check if date is valid
              if (!acc.min || date < acc.min) {
                acc.min = date
              }
              if (!acc.max || date > acc.max) {
                acc.max = date
              }
            }
            return acc
          }, { min: null, max: null })
          const _difference = Math.floor((result.max.getTime() - result.min.getTime()) / 100000 / 600)

          const _metrics = {}
          reported.forEach(r => {
            const _date = this.roundToNearestInterval(Math.round(_difference / 15), new Date(r.reportAnalysis.created_at))
            const _name = r.analysis.conclusion === 'No pathologies found' ? 'No pathologies' : r.analysis.conclusion
            if (!_metrics[_name]) {
              _metrics[_name] = []
            }

            const _record = _metrics[_name].length > 0 ? _metrics[_name].find(m => { return m.date.getTime() === _date.getTime() }) : null
            if (_record) {
              _record.confirmed += r.reportAnalysis.aiDiagnosis.confirmed ? 1 : 0
              _record.partiallyConfirmed += r.reportAnalysis.aiDiagnosis.partiallyConfirmed ? 1 : 0
              _record.notConfirmed += (!r.reportAnalysis.aiDiagnosis.confirmed && !r.reportAnalysis.aiDiagnosis.partiallyConfirmed ? 1 : 0)
            } else {
              const __record = {
                date: _date,
                confirmed: r.reportAnalysis.aiDiagnosis.confirmed ? 1 : 0,
                partiallyConfirmed: r.reportAnalysis.aiDiagnosis.partiallyConfirmed ? 1 : 0,
                notConfirmed: (!r.reportAnalysis.aiDiagnosis.confirmed && !r.reportAnalysis.aiDiagnosis.partiallyConfirmed) ? 1 : 0
              }

              _metrics[_name].push(__record)
            }
          })

          for (const prop in _metrics) {
            _metrics[prop].sort((a, b) => {
              return a.date - b.date // Compare the dates
            })
          }
          return _metrics
        } else {
          return []
        }
      } else {
        return []
      }
    },
    pmsPathologies () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        const _pathologies = {}
        const _diagnoses = {}

        this.pmsImages.forEach(i => {
          if (i.reportAnalysis && i.reportAnalysis.status === 'Completed') {
            const _analysis = i.reportAnalysis
            if (_analysis.aiDiagnosis) {
              const _name = i.analysis.conclusion === 'No pathologies found' ? 'No pathologies' : i.analysis.conclusion
              if (!_diagnoses[_name]) {
                _diagnoses[_name] = []
              }

              _diagnoses[_name].push({
                confirmed: _analysis.aiDiagnosis.confirmed,
                partiallyConfirmed: _analysis.aiDiagnosis.partiallyConfirmed,
                notConfirmed: !_analysis.aiDiagnosis.confirmed && !_analysis.aiDiagnosis.partiallyConfirmed
              })
            }

            if (_analysis.aiPathologies && _analysis.aiPathologies.length > 0) {
              _analysis.aiPathologies.forEach(p => {
                if (!_pathologies[p.name]) {
                  _pathologies[p.name] = []
                }

                _pathologies[p.name].push({
                  confirmed: p.confirmed,
                  partiallyConfirmed: p.partiallyConfirmed,
                  notConfirmed: !p.confirmed && !p.partiallyConfirmed
                })
              })
            }
          }
        })

        return {
          pathologies: _pathologies,
          diagnoses: _diagnoses
        }
      } else {
        return []
      }
    },
    thesholds () {
      return Array.from({ length: 21 }, (_, i) => parseFloat((i * 0.05).toFixed(2)))
    },
    getAi () {
      return this.images.aiLoaded
    },
    getAiStats () {
      return this._project.statsLoading
    },
    project () {
      const filteredProject = this.userProjects.find(
        (project) => project._id === this.projectId
      )
      // // // // console.log('filtered project', filteredProject)
      return filteredProject
    },
    imagesLoaded () {
      return this.images.loaded
    },
    // computeCharts () {
    //   variabilityOptions
    //   readerOptions
    //   moderatorOptions
    //   cmOptions
    //   aucOptions
    //   prOptions
    // },
    variabilityOptions () {
      // if (this.currentProject.studyType === 'Retrospective') {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['Full Agreement', 'Partial Disagreement', 'Full Disagreement']
      _options.colors = ['#28a745', '#ffc107', '#dc3545']
      _options.title.text = !this.pmsIRV ? 'Not Enough Data' : (this.pmsIRV * 100).toFixed(2) + '%'
      _options.subtitle.text = 'Ai vs Reader Variability'

      return _options
      // }
    },
    variabilitySeries () {
      return [this.pmsFullAgreement, this.pmsPartialAgreement, this.pmsDisagreement]
    },
    readerOptions () {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['Reported', 'Not Yes Reported', 'Not Yet Analysed', 'Report Analysed']
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = !this.pmsReportAnalysed ? 'Not Enough Data' : (this.pmsReportAnalysed / this.pmsReported * 100).toFixed(2) + '%'
      _options.subtitle.text = 'Reports Analysed'

      return _options
    },
    readerSeries () {
      return [this.pmsReported, this.pmsNotReported, this.pmsNotAnalysed, this.pmsReportAnalysed]
    },
    moderatorOptions () {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['Moderated', 'Rejected']
      _options.colors = ['#28a745', '#dc3545']
      _options.title.text = !this.pmsIRV ? 'Not Enough Data' : (this.pmsAB).toFixed(2)
      _options.subtitle.text = 'Automation Bias'
      return _options
    },
    moderatorSeries () {
      return [this.moderated, this.rejections.reduce((a, b) => a + b.count, 0)]
    },
    abOptions () {
      const _options = JSON.parse(JSON.stringify(this.bubbleOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = !this.pmsIRV ? 'Not Enough Data' : this.pmsAB.toFixed(2)
      _options.subtitle.text = 'Automation Bias Factor'

      return _options
    },
    abSeries () {
      return this.pmsIRVPerReader
    },
    rdOptions () {
      const _options = JSON.parse(JSON.stringify(this.radarOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = 'Agreement Factor'
      _options.subtitle.text = 'With AI Detections in Reports'

      return _options
    },
    rdSeries () {
      return this.pmsRadarPerReader
    },
    countOptions () {
      const _options = JSON.parse(JSON.stringify(this.curveOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = 'Agreement Trend'
      _options.subtitle.text = 'With AI Detections in Reports'

      return _options
    },
    countSeries () {
      return this.pmsCurvePerReader
    },
    receiveOptions () {
      const _options = JSON.parse(JSON.stringify(this.curveOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = 'Timeline Overview'
      _options.subtitle.text = 'Studies going through workflow'

      return _options
    },
    receiveSeries () {
      return [
        { name: 'Received', data: this.pmsReceiveTimeline.map(i => i.received) },
        { name: 'Processed', data: this.pmsReceiveTimeline.map(i => i.processed) },
        { name: 'Reported', data: this.pmsReceiveTimeline.map(i => i.reported) },
        { name: 'Analysed', data: this.pmsReceiveTimeline.map(i => i.analysed) },
        { name: 'Moderated', data: this.pmsReceiveTimeline.map(i => i.moderated) }
      ]
    },
    ssOptions () {
      const _options = JSON.parse(JSON.stringify(this.curveOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = 'AI Coverage'
      _options.subtitle.text = 'Timeline Metrics based on Studies Received and Processed'
      _options.legend.position = 'bottom'
      _options.legend.offsetY = 0
      _options.colors = ['#dc3545', '#28a745', '#ffc107', '#17a2b8', '#2673bc']

      return _options
    },
    ssSeries () {
      return [
        { name: 'Minimum Threshold', data: new Array(this.pmsSSMetrics.length).fill(0) },
        { name: 'Received', data: this.pmsSSMetrics.map(i => i.received) },
        { name: 'Processed', data: this.pmsSSMetrics.map(i => i.processed) }
      ]
    },
    pvOptions () {
      const _options = JSON.parse(JSON.stringify(this.curveOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = 'Processing Times'
      _options.subtitle.text = 'Timeline Metrics based on AI Processing Times'
      _options.legend.position = 'bottom'
      _options.legend.offsetY = 0
      _options.colors = ['#dc3545', '#17a2b8', '#2673bc']

      return _options
    },
    pvSeries () {
      return [
        { name: 'Minimum Threshold', data: new Array(this.pmsSSMetrics.length).fill(180) },
        { name: 'Average Processing Time', data: this.pmsSSMetrics.map(i => { return i.processing_time > 0 ? Math.round(i.processing_time / i.processed * 1000) / 1000 : 0 }) }
      ]
    },
    driftOptions () {
      const _options = JSON.parse(JSON.stringify(this.curveOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = 'Network Usage'
      _options.subtitle.text = 'Network Utilization Trendline with AI Request / Response Payloads'
      _options.legend.position = 'bottom'
      _options.legend.offsetY = 0
      _options.colors = ['#dc3545', '#ffc107', '#17a2b8', '#2673bc']

      return _options
    },
    driftSeries () {
      return [
        { name: 'Maximum Threshold', data: new Array(this.pmsSSMetrics.length).fill(50) },
        { name: 'Average Threshold', data: new Array(this.pmsSSMetrics.length).fill(25) },
        { name: 'Average Payload', data: this.pmsSSMetrics.map(i => i.processed > 0 ? i.payload / i.processed / 1024 / 1024 : 0) },
        { name: 'Average Response', data: this.pmsSSMetrics.map(i => i.processed > 0 ? i.response / i.processed / 1024 : 0) }
      ]
    },
    powerOptions () {
      const _options = JSON.parse(JSON.stringify(this.curveOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = 'Power Usage'
      _options.subtitle.text = 'Estimated Power Utilization Trendline for AI Inference'
      _options.legend.position = 'bottom'
      _options.legend.offsetY = 0
      _options.colors = ['#dc3545', '#17a2b8', '#ffc107', '#28a745']
      _options.yaxis = [
        {
          seriesName: ['Maximum Threshold', 'Total Power Consumption'],
          tickAmount: 5,
          decimalsInFloat: 2,
          labels: {
            show: false,
            style: {
              colors: ['#ffffff'],
              fontFamily: 'Lato',
              fontSize: '10px'
            },
            formatter: function (val) { return val.toFixed(2) }
          }
        },
        {
          oposite: true,
          seriesName: ['Average Threshold', 'Average Power Consumption'],
          tickAmount: 5,
          decimalsInFloat: 2,
          labels: {
            show: false,
            style: {
              colors: ['#ffffff'],
              fontFamily: 'Lato',
              fontSize: '10px'
            },
            formatter: function (val) { return val.toFixed(2) }
          }
        }
      ]

      return _options
    },
    powerSeries () {
      return [
        { name: 'Maximum Threshold', data: new Array(this.pmsSSMetrics.length).fill(20) },
        { name: 'Total Power Consumption', data: this.pmsSSMetrics.map(i => i.processed > 0 ? i.power_usage : 0) },
        { name: 'Average Threshold', data: new Array(this.pmsSSMetrics.length).fill(1.5) },
        { name: 'Average Power Consumption', data: this.pmsSSMetrics.map(i => i.processed > 0 ? i.power_usage / i.processed : 0) }
      ]
    },
    diagnosesOptions () {
      const _options = JSON.parse(JSON.stringify(this.barSeriesOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = 'Inferred Diagnosis'
      _options.subtitle.text = 'Split into Confirmed, Partially Confirmed, and Not Confirmed based on Report Analysis'
      _options.legend.position = 'bottom'
      _options.legend.offsetY = 0
      _options.colors = ['#2673bc', '#17a2b8', '#dc3545']

      _options.xaxis.labels.show = true
      _options.xaxis.categories = Object.keys(this.pmsPathologies.diagnoses)

      return _options
    },
    diagnosesSeries () {
      const series = [
        { name: 'Confirmed', data: [] },
        { name: 'Partially Confirmed', data: [] },
        { name: 'Not Confirmed', data: [] }
      ]

      const categories = Object.keys(this.pmsPathologies.diagnoses)

      categories.forEach(category => {
        let confirmedCount = 0
        let partiallyConfirmedCount = 0
        let notConfirmedCount = 0

        this.pmsPathologies.diagnoses[category].forEach(item => {
          if (item.confirmed) confirmedCount++
          if (item.partiallyConfirmed) partiallyConfirmedCount++
          if (item.notConfirmed) notConfirmedCount++
        })

        series[0].data.push(confirmedCount)
        series[1].data.push(partiallyConfirmedCount)
        series[2].data.push(notConfirmedCount)
      })

      return series
    },
    pathologiesOptions () {
      const _options = JSON.parse(JSON.stringify(this.barSeriesOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = 'Inferred Pathologies'
      _options.subtitle.text = 'Split into Confirmed, Partially Confirmed, and Not Confirmed based on Report Analysis'
      _options.legend.position = 'bottom'
      _options.legend.offsetY = 0
      _options.colors = ['#2673bc', '#17a2b8', '#dc3545']

      _options.xaxis.labels.show = true
      _options.xaxis.categories = Object.keys(this.pmsPathologies.pathologies)

      return _options
    },
    pathologiesSeries () {
      const series = [
        { name: 'Confirmed', data: [] },
        { name: 'Partially Confirmed', data: [] },
        { name: 'Not Confirmed', data: [] }
      ]

      const categories = Object.keys(this.pmsPathologies.pathologies)

      categories.forEach(category => {
        let confirmedCount = 0
        let partiallyConfirmedCount = 0
        let notConfirmedCount = 0

        this.pmsPathologies.pathologies[category].forEach(item => {
          if (item.confirmed) confirmedCount++
          if (item.partiallyConfirmed) partiallyConfirmedCount++
          if (item.notConfirmed) notConfirmedCount++
        })

        series[0].data.push(confirmedCount)
        series[1].data.push(partiallyConfirmedCount)
        series[2].data.push(notConfirmedCount)
      })

      return series
    },
    dataOptions () {
      const _options = JSON.parse(JSON.stringify(this.barOptions))
      _options.title.text = undefined

      return _options
    },
    dataSeries () {
      return [{
        name: 'Received, not Reported',
        data: [this.pmsImages.length - this.pmsReported]
      }, {
        name: 'Reported, not Analysed',
        data: [this.pmsReported - this.pmsReportAnalysed]
      }, {
        name: 'Rejected on Reporting',
        data: [0]
      }, {
        name: 'Report Analysed',
        data: [this.pmsReportAnalysed - this.pmsModerated]
      }, {
        name: 'Report Rejected',
        data: [0]
      }, {
        name: 'Moderated',
        data: [this.pmsModerated]
      }, {
        name: 'Rejected on Moderation',
        data: [0]
      }]
    },
    failed () {
      if (this.imgAiData && this.imgAiData.length > 0) {
        return this.imgAiData.filter(img => (!img.removed.find(p => p.project === this.list[0]._id)) && (img.diagnosis && (img.diagnosis === 'Error' || img.diagnosis === 'Unknown'))).length
      } else {
        return 0
      }
    },
    cmOptions () {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['Processed', 'Rejected', 'Failed', 'Unknown']
      _options.title.text = (this.pmsReportAnalysed / this.pmsImages.length * 100).toFixed(2) + '%'
      _options.subtitle.text = 'AI Coverage'

      return _options
    },
    cmSeries () {
      return [this.pmsReportAnalysed, this.pmsRejected, 0, 0]
    },
    auc () {
      const _thresholds = JSON.parse(JSON.stringify(this.metrics.filter(__metrics => __metrics.threshold !== 0)))
      const _metrics = []

      if (_thresholds && _thresholds.length > 0) {
        _metrics.push({
          threshold: 0,
          FPR: 1,
          TPR: 1,
          AUC: (1 - _thresholds[0].FPR) * 1
        })

        for (let i = 0; i < _thresholds.length; i++) {
          _metrics.push({
            threshold: _thresholds[i].threshold,
            FPR: _thresholds[i].FPR,
            TPR: _thresholds[i].TPR,
            AUC: (_thresholds[i].FPR - (_thresholds[i + 1] ? _thresholds[i + 1].FPR : 0) - 0.003175) * _thresholds[i].TPR
          })
        }
      }

      let _sum = 0
      if (_metrics.filter(_record => _record.FPR !== null && _record.TPR !== null).length > 1) {
        _metrics.forEach(_metric => {
          _sum += _metric.AUC
        })
      }

      return _sum
    },
    aucOptions () {
      const _options = JSON.parse(JSON.stringify(this.options))
      _options.xaxis.type = 'numeric'
      _options.xaxis.min = 0
      _options.xaxis.max = 1
      _options.xaxis.categories = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95]
      _options.title.text = this.auc.toFixed(4)
      _options.subtitle.text = 'Area Under Curve'

      return _options
    },
    aucSeries () {
      const _sorted = this.metrics.slice()
      _sorted.sort((a, b) => { return a.FRP - b.FRP })

      return [{
        data: _sorted.map(__metrics => { return { x: __metrics.FPR, y: (__metrics.TPR > 1 ? __metrics.TPR : __metrics.TPR) } })
      }]
    },
    prOptions () {
      const _options = JSON.parse(JSON.stringify(this.options))
      _options.xaxis.type = 'numeric'
      _options.xaxis.min = 0
      _options.xaxis.max = 1
      _options.xaxis.categories = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95]
      _options.title.text = this.metrics.find(__metrics => __metrics.threshold === 0.8) ? this.metrics.find(__metrics => __metrics.threshold === 0.8).accuracy.toFixed(4) : 0
      _options.subtitle.text = 'Precision-Recall Curve'

      return _options
    },
    prSeries () {
      return [{
        data: this.metrics.map(__metrics => { return { x: __metrics.precision !== null ? __metrics.precision : 0, y: __metrics.recall !== null ? __metrics.recall : 0 } })
      }]
    },
    rejectWatch () {
      return !this._project.loadingRejects
    },
    readers () {
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Reader') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    moderators () {
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Moderator') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    projectImagesLoaded () {
      return !this._project.loadingProjectImages
    },
    confusionMatrix () {
      const _at50 = this.aiMetrics.find(_record => _record.threshold === this.tsChoice)

      if (_at50) {
        return {
          truePositive: _at50.TP,
          falsePositive: _at50.FP,
          falseNegative: _at50.FN,
          trueNegative: _at50.TN
        }
      } else {
        return {
          truePositive: 0,
          falsePositive: 0,
          falseNegative: 0,
          trueNegative: 0
        }
      }
    },
    metrics () {
      return this.aiMetrics
    },
    dataLoaded () {
      return (this.getAi && this.projectImagesLoaded && this.imagesLoaded && this.rejectWatch)
    },
    thesholdChange () {
      return this.tsChoice
    },
    aiStatsLoading () {
      return this._project.aiStatsLoading
    },
    filterWatch () {
      return this.filterChoice
    }
  },
  mounted () {
    // // // console.log('ab')
    this.imgAiData = this.images.aiImages.filter(img => (!img.removed.find(p => p.project === this.project._id)))
    this.proImg = this._project.projectImages
    this.imgData = this.images.labelledImages.filter(img => (!img.removed.find(p => p.project === this.project._id)))
    this.rejections = this._project.rejects
    if (this._project && this._project.stats) {
      const curIndex = this._project.stats.findIndex(p => p.projectId === this.projectId)
      // console.log('ai ret', curIndex)
      if (curIndex > -1) {
        this.tsChoice = this._project.stats[curIndex].confiThes
      }
    }
    this.getChartData()
    this.calcMetrics()
  },
  methods: {
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2', 'fetchAILabelledImages']),
    ...mapActions('projectSubmission', ['getProjectImages2', 'getRejections2', 'updateConfidence']),
    hasGroup (img, val) {
      var _filter = this.project.subgroups.find(sg => sg.name === val).definiation
      // // console.log(_filter)
      var includeInFilter = false
      Object.keys(_filter).forEach(_key => {
        if (_key === 'Pathologies') {
          _filter[_key].forEach(path => {
            if (img.pathologies.has(path)) {
              includeInFilter = true
            }
          })
        } else if (_key === 'Tags') {
          _filter[_key].forEach(tag => {
            // // console.log(img.data.details)
            if (img.tags.has(tag)) {
              includeInFilter = true
            }
          })
        } else if (_key === 'Diagnosis') {
          _filter[_key].forEach(dia => {
            if (img.diagnoses.has(dia)) {
              includeInFilter = true
            }
          })
        } else if ((img.metadata && img.metadata[0] && img.metadata[0] && img.metadata[0].values.find(_meta => _meta.name === _key) && _filter[_key].includes(img.metadata[0].values.find(_meta => _meta.name === _key).value))) {
          includeInFilter = true
        }
      })
      // // // console.log('sg', inc ludeInFilter)
      return includeInFilter
    },
    getChartData () {
      if (this.getAi && this.projectImagesLoaded && this.imagesLoaded && this.rejectWatch && !this.getAiStats) {
        // // console.log(this.getAi)
        // // console.log(this.projectImagesLoaded)
        // // console.log(this.imagesLoaded)
        // // console.log(this.rejectWatch)
        this.moderated = 0
        this.fullyAnnotated = 0
        this.partiallyAnnotated = 0
        this.moderated = 0
        this.fa = 0
        this.pd = 0
        this.fd = 0
        var runningAcc = 0
        var totalAcc = 0
        let _imgData
        if (this.filterChoice !== 'All' && this.filterChoice !== '') {
          _imgData = this.imgData.filter(img => this.hasGroup(img, this.filterChoice))
        } else {
          _imgData = this.imgData
        }
        _imgData.forEach(img => {
          if (!img.removed.find(p => p.project === this.project._id)) {
            if (img.users.find(us => us.role === 'Moderator')) {
              // // console.log('mod')
              this.moderated += 1
              this.fullyAnnotated += 1
            } else if (img.users.length < this.readers.length && img.users.length > 0) {
              this.partiallyAnnotated += 1
            } else if (img.users.length > 0 && img.users.length >= this.readers.length) {
              this.fullyAnnotated += 1
            }
            var acc
            if (img.accuracy) {
              acc = img.accuracy
            } else {
              acc = 0
            }
            if (this.readers.length) {
              runningAcc += acc
              totalAcc += 1
              // // console.log('acc', acc)
              if (acc <= IOVThesholds().fullAgreement) {
                this.fa++
              } else if (acc >= IOVThesholds().fullAgreement && acc <= IOVThesholds().partialAgreement) {
                this.pd++
              } else {
                this.fd++
              }
            }
          }
        })
        // console.log('iov check', runningAcc, totalAcc)
        this.IOV = runningAcc / totalAcc
        this.notAnnotated = this.proImg.length - this.fullyAnnotated - this.partiallyAnnotated
      }
      // // // console.log('chart')
    },
    getPhase: function () {
      if (this.projectId) {
        const _project = this.list.find(_project => _project._id === this.projectId)
        const _phase = this.phases[_project.type.toLowerCase()].find(_phase => _phase.id === this.selected)
        return _phase.name
      } else {
        return ''
      }
    },
    getIcon: function () {
      if (this.projectId) {
        const _project = this.list.find(_project => _project._id === this.projectId)
        // // console.log('_phase', _project.type.toLowerCase())
        const _phase = this.phases[_project.type.toLowerCase()].find(_phase => _phase.id === this.selected)
        console.log(_project, _phase)
        return _phase.icon
      } else {
        return ''
      }
    },
    calcMetrics: function () {
      if (this.getAi && this.projectImagesLoaded && this.imagesLoaded && this.rejectWatch && !this.getAiStats) {
        const projectStats = this._project.stats.find(p => p.projectId === this.projectId)
        // console.log('ai2', projectStats, this.projectId)
        let met
        if (this.filterChoice === '' || this.filterChoice === 'All') {
          met = projectStats.stats.find(p => p.subgroup === 'All')
        } else {
          met = projectStats.stats.find(p => p.subgroup === this.filterChoice)
        }
        // console.log('ai3', met)
        if (met) {
          this.aiMetrics = met.metrics
        }
      }
    },
    calculateMedianAndCountInRange: function (numbers, ranges) {
      return ranges.map(range => {
        // Filter the array based on the current range
        const filteredNumbers = numbers.filter(num => num >= range[0] && num <= range[1])

        // Count the number of elements in the filtered subset
        const count = filteredNumbers.length

        // Calculate the median
        if (count === 0) {
          return [null, null, count]
        }

        const sortedNumbers = [...filteredNumbers].sort((a, b) => a - b)
        let median
        if (count % 2 === 1) {
          median = sortedNumbers[Math.floor(count / 2)]
        } else {
          const midIndex = Math.floor(count / 2)
          median = (sortedNumbers[midIndex - 1] + sortedNumbers[midIndex]) / 2
        }

        const std = mathjs.std(sortedNumbers)

        return [std * 100, median * 100, count]
      })
    },
    calculateAverageAndMaxInRange: function (numbers, ranges) {
      return ranges.map(range => {
        // Filter the array based on the current range
        const filteredNumbers = numbers.filter(num => num >= range[0] && num <= range[1])

        // Count the number of elements in the filtered subset
        const count = filteredNumbers.length

        // Calculate the median
        if (count === 0) {
          return [0, (range[0] + range[1]) / 2]
        }

        const sortedNumbers = [...filteredNumbers].sort((a, b) => a - b)
        const std = mathjs.std(sortedNumbers)

        return [Math.round(std * 100), (range[0] + range[1]) / 2]
      })
    },
    calculateAverageAndCountInRange: function (numbers, ranges) {
      return ranges.map(range => {
        // Filter the array based on the current range
        const filteredNumbers = numbers.filter(num => num >= range[0] && num <= range[1])

        // Count the number of elements in the filtered subset
        const count = filteredNumbers.length

        // Calculate the median
        if (count === 0) {
          return [0, (range[0] + range[1]) / 2]
        }

        return [count, (range[0] + range[1]) / 2]
      })
    },
    roundToNearestInterval: function (interval, date) {
      // Extract hours, minutes, seconds from the current date
      let hours = Math.floor(date.getHours())
      const minutes = date.getMinutes()

      // Calculate the rounded minute based on the interval
      let roundedMinutes = Math.round((minutes + (interval - minutes % interval)) / interval) * interval
      const _skipDate = roundedMinutes >= (24 * 60)

      // Ensure the number of minutes is within bounds (0-60)
      if (roundedMinutes >= 60) {
        const _iterations = Math.floor(roundedMinutes / 60)
        for (let i = 0; i < _iterations; i++) {
          roundedMinutes -= 60 // If it's over 60, increase hours and reset to 0
        }
      }

      if (hours > 24) {
        hours -= 24
      }

      // Create a new date with the rounded time
      const roundedDate = new Date(date)
      roundedDate.setHours(_skipDate ? 0 : hours)
      roundedDate.setMinutes(roundedMinutes)
      roundedDate.setSeconds(0) // Set seconds to 0
      roundedDate.setMilliseconds(0)

      return roundedDate
    },
    datedDiagnosisOptions: function (name) {
      const _options = JSON.parse(JSON.stringify(this.curveOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.chart.height = 150
      _options.title.text = name
      _options.title.style.fontSize = '16px'
      // _options.subtitle.text = 'Timeline Metrics based on Radiology Report Analysis'
      // _options.subtitle.offsetY = 20
      delete _options.subtitle
      _options.legend.show = false
      _options.legend.position = 'left'
      _options.legend.horizontalAlign = 'center'
      _options.legend.offsetY = 20
      _options.colors = ['#2673bc', '#17a2b8', '#dc3545']

      return _options
    },
    datedDiagnosisSeries: function (name) {
      return [
        { name: 'Confirmed', data: this.pmsDatedDiagnoses[name].map(i => i.confirmed) },
        { name: 'Partially Confirmed', data: this.pmsDatedDiagnoses[name].map(i => i.partiallyConfirmed) },
        { name: 'Not Confirmed', data: this.pmsDatedDiagnoses[name].map(i => i.notConfirmed) }
      ]
    },
    setItemsPerPage (x) {
      this.itemsPerPage = x
    },
    setPage (x) {
      this.pageNumber = x
    },
    nextPage () {
      this.pageNumber++
    },
    prevPage () {
      this.pageNumber--
    },
    formatTime: function (seconds, includeMs = false) {
      if (seconds < 60) {
        if (includeMs && seconds < 1) {
          return `${Math.round(seconds * 1000)} ms`
        }
        return `${seconds.toFixed(includeMs ? 3 : 0)} sec`
      } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        return `${minutes}m ${remainingSeconds.toFixed(includeMs ? 1 : 0)}s`
      } else {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const remainingSeconds = seconds % 60
        return `${hours}h ${minutes}m ${remainingSeconds.toFixed(0)}s`
      }
    }
  },
  watch: {
    getAi (_new) {
      if (_new) {
        this.imgAiData = this.images.aiImages.filter(img => (!img.removed.find(p => p.project === this.project._id)))
        this.getChartData()
        this.calcMetrics()
      }
    },
    projectImagesLoaded (_new) {
      if (_new) {
        this.proImg = this._project.projectImages
        this.getChartData()
        this.calcMetrics()
      }
    },
    imagesLoaded (_new) {
      if (_new) {
        this.imgData = this.images.labelledImages.filter(img => (!img.removed.find(p => p.project === this.project._id)))
        this.getChartData()
        this.calcMetrics()
      }
    },
    // add stats watcher
    rejectWatch (_new) {
      if (_new) {
        this.rejections = this._project.rejects
        this.getChartData()
        this.calcMetrics()
      }
    },
    thesholdChange (_new) {
      this.rejections = this._project.rejects
      this.getChartData()
      this.calcMetrics()
      this.updateConfidence({ confiThes: this.tsChoice, projectId: this.projectId })
    },
    filterWatch (_new) {
      this.rejections = this._project.rejects
      this.getChartData()
      this.calcMetrics()
    },
    aiStatsLoading (_new) {
      if (!_new) {
        this.rejections = this._project.rejects
        this.getChartData()
        this.calcMetrics()
      }
    },
    dataLoaded (_new) {
      // // console.log(_new)
    }
  }
}
</script>

<style>
.bottom-to-top {
  writing-mode: vertical-lr;
  text-orientation: mixed;
}
</style>
